<template>
  <div class="pb-0 md:pb-5 py-5">
    <slot />
    <div class="product-gallery h-full relative">
      <Carousel v-if="_.get(variant, 'images.length', 0) > 1" :gallery="variant.images" />
      <div v-else class="w-full h-full flex items-start justify-center text-light">
        <Image
          :src="variant?.image?.url || _.get(variant, 'images.0.url', null) || siteSettings.product.placeholderImage[config.public.layoutDesign]"
          :alt="variant.name"
          :width="768"
          :height="576"
          sizes="280px xs:588px sm:460px lg:588px"
          object-fit="cover"
          class="rounded"
          class-name="cursor-zoom-in bg-radial-gradient"
          @click="showImage"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import _ from 'lodash-es';
import GLightbox from 'glightbox-ssr';
import { siteSettings } from "@settings/site.settings";
import Image from "@components/ui/image";
import Carousel from "@components/ui/carousel";

const props = defineProps({
  variant: {
    type: Object,
    default: () => { }
  }
})

const config = useRuntimeConfig();

const lightbox = GLightbox({ touchNavigation: true, selector: null });

const mapElements = (value) => _.map(_.filter(value, image => image?.url), image => ({
  href: image.url,
  type: 'image'
}));

onMounted(() => {
  lightbox.setElements(mapElements(props.variant?.image ? [ props.variant?.image ] : []));
});

const showImage = () => lightbox.open();

</script>
