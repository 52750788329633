import { default as _91hash_93eswwpaB4LwMeta } from "/home/aquarist/www/prod/pages/activate-account/[hash].vue?macro=true";
import { default as index5ASEsh6zGoMeta } from "/home/aquarist/www/prod/pages/activate-account/index.vue?macro=true";
import { default as appB5miAHyDpQMeta } from "/home/aquarist/www/prod/pages/app.vue?macro=true";
import { default as _91slug_93BwJNqQjryjMeta } from "/home/aquarist/www/prod/pages/auctions/[slug].vue?macro=true";
import { default as indexc0Auw5ZO0KMeta } from "/home/aquarist/www/prod/pages/auctions/index.vue?macro=true";
import { default as _91slug_93dmpDMR9xBHMeta } from "/home/aquarist/www/prod/pages/category/[slug].vue?macro=true";
import { default as indexd5Yw5YZSpuMeta } from "/home/aquarist/www/prod/pages/category/index.vue?macro=true";
import { default as change_45passwordk5wVzUGMfzMeta } from "/home/aquarist/www/prod/pages/change-password.vue?macro=true";
import { default as _91cart_id_93fKniYhGJsqMeta } from "/home/aquarist/www/prod/pages/checkout/[cart_id].vue?macro=true";
import { default as index6N2Kyglef0Meta } from "/home/aquarist/www/prod/pages/checkout/index.vue?macro=true";
import { default as _91ad_id_93enIn0eRKbIMeta } from "/home/aquarist/www/prod/pages/classified-ad/edit/[ad_id].vue?macro=true";
import { default as indexDyKwZ3wdzvMeta } from "/home/aquarist/www/prod/pages/classified-ad/edit/index.vue?macro=true";
import { default as indexT8M3u4p3HMMeta } from "/home/aquarist/www/prod/pages/classified-ad/index.vue?macro=true";
import { default as newqF3K9B1Nn3Meta } from "/home/aquarist/www/prod/pages/classified-ad/new.vue?macro=true";
import { default as indexJkgZq6o2JnMeta } from "/home/aquarist/www/prod/pages/classified-ads/index.vue?macro=true";
import { default as myp1sW3nF7ONMeta } from "/home/aquarist/www/prod/pages/classified-ads/my.vue?macro=true";
import { default as contactu93tYJ4mkQMeta } from "/home/aquarist/www/prod/pages/contact.vue?macro=true";
import { default as _91hash_935Q43ibMduyMeta } from "/home/aquarist/www/prod/pages/delete-account/[hash].vue?macro=true";
import { default as indexiE8lBSw9gaMeta } from "/home/aquarist/www/prod/pages/delete-account/index.vue?macro=true";
import { default as favoritesuYUUm280XNMeta } from "/home/aquarist/www/prod/pages/favorites.vue?macro=true";
import { default as forgot_45password1nAsOd7bZXMeta } from "/home/aquarist/www/prod/pages/forgot-password.vue?macro=true";
import { default as helpM0fuJk0hfyMeta } from "/home/aquarist/www/prod/pages/help.vue?macro=true";
import { default as indexjNGEdGYgnOMeta } from "/home/aquarist/www/prod/pages/index.vue?macro=true";
import { default as _91slug_93UeT9KRdaY4Meta } from "/home/aquarist/www/prod/pages/inspiration/[slug].vue?macro=true";
import { default as addTNhi6bfHzgMeta } from "/home/aquarist/www/prod/pages/inspiration/add.vue?macro=true";
import { default as _91content_id_93NQOmhqY6mxMeta } from "/home/aquarist/www/prod/pages/inspiration/edit/[content_id].vue?macro=true";
import { default as indexuorDNBNkPtMeta } from "/home/aquarist/www/prod/pages/inspiration/edit/index.vue?macro=true";
import { default as indexxZl64Y2VyUMeta } from "/home/aquarist/www/prod/pages/inspiration/index.vue?macro=true";
import { default as _91slug_93MJSAER1vFZMeta } from "/home/aquarist/www/prod/pages/inspiration/type/[slug].vue?macro=true";
import { default as index8L8QSzB42lMeta } from "/home/aquarist/www/prod/pages/inspiration/type/index.vue?macro=true";
import { default as loginO4PQPZrUdGMeta } from "/home/aquarist/www/prod/pages/login.vue?macro=true";
import { default as logoutixCudY8mQ5Meta } from "/home/aquarist/www/prod/pages/logout.vue?macro=true";
import { default as _91slug_93r0DdiXNiXGMeta } from "/home/aquarist/www/prod/pages/news/[slug].vue?macro=true";
import { default as _91slug_93tJPGhIXm5OMeta } from "/home/aquarist/www/prod/pages/news/about/[slug].vue?macro=true";
import { default as indexAkACqhDmWaMeta } from "/home/aquarist/www/prod/pages/news/about/index.vue?macro=true";
import { default as indexaps9REVB6uMeta } from "/home/aquarist/www/prod/pages/news/index.vue?macro=true";
import { default as _91slug_93ETNZaqS9XkMeta } from "/home/aquarist/www/prod/pages/offers/[slug].vue?macro=true";
import { default as indexI7GXPuEPptMeta } from "/home/aquarist/www/prod/pages/offers/index.vue?macro=true";
import { default as _91cart_id_93Rnr0Pghxq8Meta } from "/home/aquarist/www/prod/pages/orders/[cart_id].vue?macro=true";
import { default as indexJRZ20ZQj6EMeta } from "/home/aquarist/www/prod/pages/orders/index.vue?macro=true";
import { default as privacy8vIdl4MH0DMeta } from "/home/aquarist/www/prod/pages/privacy.vue?macro=true";
import { default as _91slug_932FLyuAd54aMeta } from "/home/aquarist/www/prod/pages/product/[slug].vue?macro=true";
import { default as indexAk3i5eZWBzMeta } from "/home/aquarist/www/prod/pages/product/index.vue?macro=true";
import { default as profileMhUqW6cbF7Meta } from "/home/aquarist/www/prod/pages/profile.vue?macro=true";
import { default as _91slug_934K2BktOLBzMeta } from "/home/aquarist/www/prod/pages/reference/[slug].vue?macro=true";
import { default as indexWWHIoYW1xVMeta } from "/home/aquarist/www/prod/pages/reference/index.vue?macro=true";
import { default as registerd0HqpGimOyMeta } from "/home/aquarist/www/prod/pages/register.vue?macro=true";
import { default as _91slug_934MUTTUtmLDMeta } from "/home/aquarist/www/prod/pages/requests/[slug].vue?macro=true";
import { default as indexlKgoQvJ2htMeta } from "/home/aquarist/www/prod/pages/requests/index.vue?macro=true";
import { default as _91hash_93yeuyM22kF1Meta } from "/home/aquarist/www/prod/pages/reset-password/[hash].vue?macro=true";
import { default as indexQPRNoeiVRFMeta } from "/home/aquarist/www/prod/pages/reset-password/index.vue?macro=true";
import { default as rulesn3XwR57bcDMeta } from "/home/aquarist/www/prod/pages/rules.vue?macro=true";
import { default as _91cart_id_93N8O540GIJyMeta } from "/home/aquarist/www/prod/pages/sales/[cart_id].vue?macro=true";
import { default as indexs4hR3DrB06Meta } from "/home/aquarist/www/prod/pages/sales/index.vue?macro=true";
import { default as indexWiNTnodrePMeta } from "/home/aquarist/www/prod/pages/settings/index.vue?macro=true";
import { default as notificationsIyjKSVvqbXMeta } from "/home/aquarist/www/prod/pages/settings/notifications.vue?macro=true";
import { default as _91watchdog_id_93STgHy23AYdMeta } from "/home/aquarist/www/prod/pages/settings/watchdog/[watchdog_id].vue?macro=true";
import { default as indexOUHMUlDnzvMeta } from "/home/aquarist/www/prod/pages/settings/watchdog/index.vue?macro=true";
import { default as _91supplier_id_93xbSn9EYT8wMeta } from "/home/aquarist/www/prod/pages/supplier/[supplier_id].vue?macro=true";
import { default as indexws6HrfhRhtMeta } from "/home/aquarist/www/prod/pages/supplier/index.vue?macro=true";
import { default as terms7cdYR5pWxkMeta } from "/home/aquarist/www/prod/pages/terms.vue?macro=true";
import { default as _91cart_id_93yUpHDoC1nvMeta } from "/home/aquarist/www/prod/pages/unfinished/[cart_id].vue?macro=true";
import { default as indexAbxjC6ntdJMeta } from "/home/aquarist/www/prod/pages/unfinished/index.vue?macro=true";
import { default as why_45with_45usspdMZjGNdNMeta } from "/home/aquarist/www/prod/pages/why-with-us.vue?macro=true";
export default [
  {
    name: "activate-account-hash___cs",
    path: "/aktivace-uctu/:hash()",
    component: () => import("/home/aquarist/www/prod/pages/activate-account/[hash].vue")
  },
  {
    name: "activate-account-hash___en",
    path: "/en/activate-account/:hash()",
    component: () => import("/home/aquarist/www/prod/pages/activate-account/[hash].vue")
  },
  {
    name: "activate-account-hash___pl",
    path: "/pl/aktywacja-konta/:hash()",
    component: () => import("/home/aquarist/www/prod/pages/activate-account/[hash].vue")
  },
  {
    name: "activate-account-hash___sk",
    path: "/sk/aktivacia-uctu/:hash()",
    component: () => import("/home/aquarist/www/prod/pages/activate-account/[hash].vue")
  },
  {
    name: "activate-account-hash___de",
    path: "/de/kontoaktivierung/:hash()",
    component: () => import("/home/aquarist/www/prod/pages/activate-account/[hash].vue")
  },
  {
    name: "activate-account___cs",
    path: "/aktivace-uctu",
    component: () => import("/home/aquarist/www/prod/pages/activate-account/index.vue")
  },
  {
    name: "activate-account___en",
    path: "/en/activate-account",
    component: () => import("/home/aquarist/www/prod/pages/activate-account/index.vue")
  },
  {
    name: "activate-account___pl",
    path: "/pl/aktywacja-konta",
    component: () => import("/home/aquarist/www/prod/pages/activate-account/index.vue")
  },
  {
    name: "activate-account___sk",
    path: "/sk/aktivacia-uctu",
    component: () => import("/home/aquarist/www/prod/pages/activate-account/index.vue")
  },
  {
    name: "activate-account___de",
    path: "/de/kontoaktivierung",
    component: () => import("/home/aquarist/www/prod/pages/activate-account/index.vue")
  },
  {
    name: "app___cs",
    path: "/aplikace",
    component: () => import("/home/aquarist/www/prod/pages/app.vue")
  },
  {
    name: "app___en",
    path: "/en/app",
    component: () => import("/home/aquarist/www/prod/pages/app.vue")
  },
  {
    name: "app___pl",
    path: "/pl/aplikacja",
    component: () => import("/home/aquarist/www/prod/pages/app.vue")
  },
  {
    name: "app___sk",
    path: "/sk/aplikacia",
    component: () => import("/home/aquarist/www/prod/pages/app.vue")
  },
  {
    name: "app___de",
    path: "/de/app",
    component: () => import("/home/aquarist/www/prod/pages/app.vue")
  },
  {
    name: "auctions-slug___cs",
    path: "/aukce/:slug()",
    meta: _91slug_93BwJNqQjryjMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/auctions/[slug].vue")
  },
  {
    name: "auctions-slug___en",
    path: "/en/auctions/:slug()",
    meta: _91slug_93BwJNqQjryjMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/auctions/[slug].vue")
  },
  {
    name: "auctions-slug___pl",
    path: "/pl/aukcje/:slug()",
    meta: _91slug_93BwJNqQjryjMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/auctions/[slug].vue")
  },
  {
    name: "auctions-slug___sk",
    path: "/sk/aukcie/:slug()",
    meta: _91slug_93BwJNqQjryjMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/auctions/[slug].vue")
  },
  {
    name: "auctions-slug___de",
    path: "/de/auktionen/:slug()",
    meta: _91slug_93BwJNqQjryjMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/auctions/[slug].vue")
  },
  {
    name: "auctions___cs",
    path: "/aukce",
    meta: indexc0Auw5ZO0KMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/auctions/index.vue")
  },
  {
    name: "auctions___en",
    path: "/en/auctions",
    meta: indexc0Auw5ZO0KMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/auctions/index.vue")
  },
  {
    name: "auctions___pl",
    path: "/pl/aukcje",
    meta: indexc0Auw5ZO0KMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/auctions/index.vue")
  },
  {
    name: "auctions___sk",
    path: "/sk/aukcie",
    meta: indexc0Auw5ZO0KMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/auctions/index.vue")
  },
  {
    name: "auctions___de",
    path: "/de/auktionen",
    meta: indexc0Auw5ZO0KMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/auctions/index.vue")
  },
  {
    name: "category-slug___cs",
    path: "/kategorie/:slug()",
    meta: _91slug_93dmpDMR9xBHMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/category/[slug].vue")
  },
  {
    name: "category-slug___en",
    path: "/en/category/:slug()",
    meta: _91slug_93dmpDMR9xBHMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/category/[slug].vue")
  },
  {
    name: "category-slug___pl",
    path: "/pl/kategoria/:slug()",
    meta: _91slug_93dmpDMR9xBHMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/category/[slug].vue")
  },
  {
    name: "category-slug___sk",
    path: "/sk/kategoria/:slug()",
    meta: _91slug_93dmpDMR9xBHMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/category/[slug].vue")
  },
  {
    name: "category-slug___de",
    path: "/de/kategorie/:slug()",
    meta: _91slug_93dmpDMR9xBHMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/category/[slug].vue")
  },
  {
    name: "category___cs",
    path: "/kategorie",
    meta: indexd5Yw5YZSpuMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/category/index.vue")
  },
  {
    name: "category___en",
    path: "/en/category",
    meta: indexd5Yw5YZSpuMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/category/index.vue")
  },
  {
    name: "category___pl",
    path: "/pl/kategoria",
    meta: indexd5Yw5YZSpuMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/category/index.vue")
  },
  {
    name: "category___sk",
    path: "/sk/kategoria",
    meta: indexd5Yw5YZSpuMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/category/index.vue")
  },
  {
    name: "category___de",
    path: "/de/kategorie",
    meta: indexd5Yw5YZSpuMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/category/index.vue")
  },
  {
    name: "change-password___cs",
    path: "/zmena-hesla",
    component: () => import("/home/aquarist/www/prod/pages/change-password.vue")
  },
  {
    name: "change-password___en",
    path: "/en/change-password",
    component: () => import("/home/aquarist/www/prod/pages/change-password.vue")
  },
  {
    name: "change-password___pl",
    path: "/pl/zmiana-hasla",
    component: () => import("/home/aquarist/www/prod/pages/change-password.vue")
  },
  {
    name: "change-password___sk",
    path: "/sk/zmena-hesla",
    component: () => import("/home/aquarist/www/prod/pages/change-password.vue")
  },
  {
    name: "change-password___de",
    path: "/de/passwort-andern",
    component: () => import("/home/aquarist/www/prod/pages/change-password.vue")
  },
  {
    name: "checkout-cart_id___cs",
    path: "/kosik/:cart_id()",
    component: () => import("/home/aquarist/www/prod/pages/checkout/[cart_id].vue")
  },
  {
    name: "checkout-cart_id___en",
    path: "/en/checkout/:cart_id()",
    component: () => import("/home/aquarist/www/prod/pages/checkout/[cart_id].vue")
  },
  {
    name: "checkout-cart_id___pl",
    path: "/pl/kosz/:cart_id()",
    component: () => import("/home/aquarist/www/prod/pages/checkout/[cart_id].vue")
  },
  {
    name: "checkout-cart_id___sk",
    path: "/sk/kosik/:cart_id()",
    component: () => import("/home/aquarist/www/prod/pages/checkout/[cart_id].vue")
  },
  {
    name: "checkout-cart_id___de",
    path: "/de/korb/:cart_id()",
    component: () => import("/home/aquarist/www/prod/pages/checkout/[cart_id].vue")
  },
  {
    name: "checkout___cs",
    path: "/kosik",
    meta: index6N2Kyglef0Meta || {},
    component: () => import("/home/aquarist/www/prod/pages/checkout/index.vue")
  },
  {
    name: "checkout___en",
    path: "/en/checkout",
    meta: index6N2Kyglef0Meta || {},
    component: () => import("/home/aquarist/www/prod/pages/checkout/index.vue")
  },
  {
    name: "checkout___pl",
    path: "/pl/kosz",
    meta: index6N2Kyglef0Meta || {},
    component: () => import("/home/aquarist/www/prod/pages/checkout/index.vue")
  },
  {
    name: "checkout___sk",
    path: "/sk/kosik",
    meta: index6N2Kyglef0Meta || {},
    component: () => import("/home/aquarist/www/prod/pages/checkout/index.vue")
  },
  {
    name: "checkout___de",
    path: "/de/korb",
    meta: index6N2Kyglef0Meta || {},
    component: () => import("/home/aquarist/www/prod/pages/checkout/index.vue")
  },
  {
    name: "classified-ad-edit-ad_id___cs",
    path: "/inzerat/editace/:ad_id()",
    component: () => import("/home/aquarist/www/prod/pages/classified-ad/edit/[ad_id].vue")
  },
  {
    name: "classified-ad-edit-ad_id___en",
    path: "/en/classified-ad/edit/:ad_id()",
    component: () => import("/home/aquarist/www/prod/pages/classified-ad/edit/[ad_id].vue")
  },
  {
    name: "classified-ad-edit-ad_id___pl",
    path: "/pl/ogloszenie/redagowanie/:ad_id()",
    component: () => import("/home/aquarist/www/prod/pages/classified-ad/edit/[ad_id].vue")
  },
  {
    name: "classified-ad-edit-ad_id___sk",
    path: "/sk/inzerat/editacia/:ad_id()",
    component: () => import("/home/aquarist/www/prod/pages/classified-ad/edit/[ad_id].vue")
  },
  {
    name: "classified-ad-edit-ad_id___de",
    path: "/de/anzeige/editierung/:ad_id()",
    component: () => import("/home/aquarist/www/prod/pages/classified-ad/edit/[ad_id].vue")
  },
  {
    name: "classified-ad-edit___cs",
    path: "/inzerat/editace",
    component: () => import("/home/aquarist/www/prod/pages/classified-ad/edit/index.vue")
  },
  {
    name: "classified-ad-edit___en",
    path: "/en/classified-ad/edit",
    component: () => import("/home/aquarist/www/prod/pages/classified-ad/edit/index.vue")
  },
  {
    name: "classified-ad-edit___pl",
    path: "/pl/ogloszenie/redagowanie",
    component: () => import("/home/aquarist/www/prod/pages/classified-ad/edit/index.vue")
  },
  {
    name: "classified-ad-edit___sk",
    path: "/sk/inzerat/editacia",
    component: () => import("/home/aquarist/www/prod/pages/classified-ad/edit/index.vue")
  },
  {
    name: "classified-ad-edit___de",
    path: "/de/anzeige/editierung",
    component: () => import("/home/aquarist/www/prod/pages/classified-ad/edit/index.vue")
  },
  {
    name: "classified-ad___cs",
    path: "/inzerat",
    component: () => import("/home/aquarist/www/prod/pages/classified-ad/index.vue")
  },
  {
    name: "classified-ad___en",
    path: "/en/classified-ad",
    component: () => import("/home/aquarist/www/prod/pages/classified-ad/index.vue")
  },
  {
    name: "classified-ad___pl",
    path: "/pl/ogloszenie",
    component: () => import("/home/aquarist/www/prod/pages/classified-ad/index.vue")
  },
  {
    name: "classified-ad___sk",
    path: "/sk/inzerat",
    component: () => import("/home/aquarist/www/prod/pages/classified-ad/index.vue")
  },
  {
    name: "classified-ad___de",
    path: "/de/anzeige",
    component: () => import("/home/aquarist/www/prod/pages/classified-ad/index.vue")
  },
  {
    name: "classified-ad-new___cs",
    path: "/inzerat/novy",
    component: () => import("/home/aquarist/www/prod/pages/classified-ad/new.vue")
  },
  {
    name: "classified-ad-new___en",
    path: "/en/classified-ad/new",
    component: () => import("/home/aquarist/www/prod/pages/classified-ad/new.vue")
  },
  {
    name: "classified-ad-new___pl",
    path: "/pl/ogloszenie/nowe",
    component: () => import("/home/aquarist/www/prod/pages/classified-ad/new.vue")
  },
  {
    name: "classified-ad-new___sk",
    path: "/sk/inzerat/novy",
    component: () => import("/home/aquarist/www/prod/pages/classified-ad/new.vue")
  },
  {
    name: "classified-ad-new___de",
    path: "/de/anzeige/neue",
    component: () => import("/home/aquarist/www/prod/pages/classified-ad/new.vue")
  },
  {
    name: "classified-ads___cs",
    path: "/inzeraty",
    meta: indexJkgZq6o2JnMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/classified-ads/index.vue")
  },
  {
    name: "classified-ads___en",
    path: "/en/classified-ads",
    meta: indexJkgZq6o2JnMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/classified-ads/index.vue")
  },
  {
    name: "classified-ads___pl",
    path: "/pl/ogloszenia",
    meta: indexJkgZq6o2JnMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/classified-ads/index.vue")
  },
  {
    name: "classified-ads___sk",
    path: "/sk/inzeraty",
    meta: indexJkgZq6o2JnMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/classified-ads/index.vue")
  },
  {
    name: "classified-ads___de",
    path: "/de/anzeigen",
    meta: indexJkgZq6o2JnMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/classified-ads/index.vue")
  },
  {
    name: "classified-ads-my___cs",
    path: "/inzeraty/moje",
    meta: myp1sW3nF7ONMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/classified-ads/my.vue")
  },
  {
    name: "classified-ads-my___en",
    path: "/en/classified-ads/my",
    meta: myp1sW3nF7ONMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/classified-ads/my.vue")
  },
  {
    name: "classified-ads-my___pl",
    path: "/pl/ogloszenia/moje",
    meta: myp1sW3nF7ONMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/classified-ads/my.vue")
  },
  {
    name: "classified-ads-my___sk",
    path: "/sk/inzeraty/moje",
    meta: myp1sW3nF7ONMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/classified-ads/my.vue")
  },
  {
    name: "classified-ads-my___de",
    path: "/de/anzeigen/meine",
    meta: myp1sW3nF7ONMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/classified-ads/my.vue")
  },
  {
    name: "contact___cs",
    path: "/kontakt",
    component: () => import("/home/aquarist/www/prod/pages/contact.vue")
  },
  {
    name: "contact___en",
    path: "/en/contact",
    component: () => import("/home/aquarist/www/prod/pages/contact.vue")
  },
  {
    name: "contact___pl",
    path: "/pl/kontakt",
    component: () => import("/home/aquarist/www/prod/pages/contact.vue")
  },
  {
    name: "contact___sk",
    path: "/sk/kontakt",
    component: () => import("/home/aquarist/www/prod/pages/contact.vue")
  },
  {
    name: "contact___de",
    path: "/de/kontakt",
    component: () => import("/home/aquarist/www/prod/pages/contact.vue")
  },
  {
    name: "delete-account-hash___cs",
    path: "/smazani-uctu/:hash()",
    component: () => import("/home/aquarist/www/prod/pages/delete-account/[hash].vue")
  },
  {
    name: "delete-account-hash___en",
    path: "/en/delete-account/:hash()",
    component: () => import("/home/aquarist/www/prod/pages/delete-account/[hash].vue")
  },
  {
    name: "delete-account-hash___pl",
    path: "/pl/usuniecie-konta/:hash()",
    component: () => import("/home/aquarist/www/prod/pages/delete-account/[hash].vue")
  },
  {
    name: "delete-account-hash___sk",
    path: "/sk/zmazanie-uctu/:hash()",
    component: () => import("/home/aquarist/www/prod/pages/delete-account/[hash].vue")
  },
  {
    name: "delete-account-hash___de",
    path: "/de/kontoloschung/:hash()",
    component: () => import("/home/aquarist/www/prod/pages/delete-account/[hash].vue")
  },
  {
    name: "delete-account___cs",
    path: "/smazani-uctu",
    component: () => import("/home/aquarist/www/prod/pages/delete-account/index.vue")
  },
  {
    name: "delete-account___en",
    path: "/en/delete-account",
    component: () => import("/home/aquarist/www/prod/pages/delete-account/index.vue")
  },
  {
    name: "delete-account___pl",
    path: "/pl/usuniecie-konta",
    component: () => import("/home/aquarist/www/prod/pages/delete-account/index.vue")
  },
  {
    name: "delete-account___sk",
    path: "/sk/zmazanie-uctu",
    component: () => import("/home/aquarist/www/prod/pages/delete-account/index.vue")
  },
  {
    name: "delete-account___de",
    path: "/de/kontoloschung",
    component: () => import("/home/aquarist/www/prod/pages/delete-account/index.vue")
  },
  {
    name: "favorites___cs",
    path: "/oblibene",
    meta: favoritesuYUUm280XNMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/favorites.vue")
  },
  {
    name: "favorites___en",
    path: "/en/favorites",
    meta: favoritesuYUUm280XNMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/favorites.vue")
  },
  {
    name: "favorites___pl",
    path: "/pl/ulubione",
    meta: favoritesuYUUm280XNMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/favorites.vue")
  },
  {
    name: "favorites___sk",
    path: "/sk/oblubene",
    meta: favoritesuYUUm280XNMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/favorites.vue")
  },
  {
    name: "favorites___de",
    path: "/de/beliebte",
    meta: favoritesuYUUm280XNMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/favorites.vue")
  },
  {
    name: "forgot-password___cs",
    path: "/zapomenute-heslo",
    component: () => import("/home/aquarist/www/prod/pages/forgot-password.vue")
  },
  {
    name: "forgot-password___en",
    path: "/en/forgot-password",
    component: () => import("/home/aquarist/www/prod/pages/forgot-password.vue")
  },
  {
    name: "forgot-password___pl",
    path: "/pl/zapomniane-haslo",
    component: () => import("/home/aquarist/www/prod/pages/forgot-password.vue")
  },
  {
    name: "forgot-password___sk",
    path: "/sk/zabudnute-heslo",
    component: () => import("/home/aquarist/www/prod/pages/forgot-password.vue")
  },
  {
    name: "forgot-password___de",
    path: "/de/passwort-vergessen",
    component: () => import("/home/aquarist/www/prod/pages/forgot-password.vue")
  },
  {
    name: "help___cs",
    path: "/pomoc",
    component: () => import("/home/aquarist/www/prod/pages/help.vue")
  },
  {
    name: "help___en",
    path: "/en/help",
    component: () => import("/home/aquarist/www/prod/pages/help.vue")
  },
  {
    name: "help___pl",
    path: "/pl/pomoc",
    component: () => import("/home/aquarist/www/prod/pages/help.vue")
  },
  {
    name: "help___sk",
    path: "/sk/pomoc",
    component: () => import("/home/aquarist/www/prod/pages/help.vue")
  },
  {
    name: "help___de",
    path: "/de/hilfe",
    component: () => import("/home/aquarist/www/prod/pages/help.vue")
  },
  {
    name: "index___cs",
    path: "/",
    meta: indexjNGEdGYgnOMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexjNGEdGYgnOMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/index.vue")
  },
  {
    name: "index___pl",
    path: "/pl",
    meta: indexjNGEdGYgnOMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/index.vue")
  },
  {
    name: "index___sk",
    path: "/sk",
    meta: indexjNGEdGYgnOMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/index.vue")
  },
  {
    name: "index___de",
    path: "/de",
    meta: indexjNGEdGYgnOMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/index.vue")
  },
  {
    name: "inspiration-slug___cs",
    path: "/inspirace/:slug()",
    component: () => import("/home/aquarist/www/prod/pages/inspiration/[slug].vue")
  },
  {
    name: "inspiration-slug___en",
    path: "/en/inspiration/:slug()",
    component: () => import("/home/aquarist/www/prod/pages/inspiration/[slug].vue")
  },
  {
    name: "inspiration-slug___pl",
    path: "/pl/inspiracja/:slug()",
    component: () => import("/home/aquarist/www/prod/pages/inspiration/[slug].vue")
  },
  {
    name: "inspiration-slug___sk",
    path: "/sk/inspiracia/:slug()",
    component: () => import("/home/aquarist/www/prod/pages/inspiration/[slug].vue")
  },
  {
    name: "inspiration-slug___de",
    path: "/de/inspiration/:slug()",
    component: () => import("/home/aquarist/www/prod/pages/inspiration/[slug].vue")
  },
  {
    name: "inspiration-add___cs",
    path: "/inspirace/nova",
    component: () => import("/home/aquarist/www/prod/pages/inspiration/add.vue")
  },
  {
    name: "inspiration-add___en",
    path: "/en/inspiration/add",
    component: () => import("/home/aquarist/www/prod/pages/inspiration/add.vue")
  },
  {
    name: "inspiration-add___pl",
    path: "/pl/inspiracja/nowa",
    component: () => import("/home/aquarist/www/prod/pages/inspiration/add.vue")
  },
  {
    name: "inspiration-add___sk",
    path: "/sk/inspiracia/nova",
    component: () => import("/home/aquarist/www/prod/pages/inspiration/add.vue")
  },
  {
    name: "inspiration-add___de",
    path: "/de/inspiration/neue",
    component: () => import("/home/aquarist/www/prod/pages/inspiration/add.vue")
  },
  {
    name: "inspiration-edit-content_id___cs",
    path: "/inspirace/editace/:content_id()",
    component: () => import("/home/aquarist/www/prod/pages/inspiration/edit/[content_id].vue")
  },
  {
    name: "inspiration-edit-content_id___en",
    path: "/en/inspiration/edit/:content_id()",
    component: () => import("/home/aquarist/www/prod/pages/inspiration/edit/[content_id].vue")
  },
  {
    name: "inspiration-edit-content_id___pl",
    path: "/pl/inspiracja/redagowanie/:content_id()",
    component: () => import("/home/aquarist/www/prod/pages/inspiration/edit/[content_id].vue")
  },
  {
    name: "inspiration-edit-content_id___sk",
    path: "/sk/inspiracia/editacia/:content_id()",
    component: () => import("/home/aquarist/www/prod/pages/inspiration/edit/[content_id].vue")
  },
  {
    name: "inspiration-edit-content_id___de",
    path: "/de/inspiration/editierung/:content_id()",
    component: () => import("/home/aquarist/www/prod/pages/inspiration/edit/[content_id].vue")
  },
  {
    name: "inspiration-edit___cs",
    path: "/inspirace/editace",
    component: () => import("/home/aquarist/www/prod/pages/inspiration/edit/index.vue")
  },
  {
    name: "inspiration-edit___en",
    path: "/en/inspiration/edit",
    component: () => import("/home/aquarist/www/prod/pages/inspiration/edit/index.vue")
  },
  {
    name: "inspiration-edit___pl",
    path: "/pl/inspiracja/redagowanie",
    component: () => import("/home/aquarist/www/prod/pages/inspiration/edit/index.vue")
  },
  {
    name: "inspiration-edit___sk",
    path: "/sk/inspiracia/editacia",
    component: () => import("/home/aquarist/www/prod/pages/inspiration/edit/index.vue")
  },
  {
    name: "inspiration-edit___de",
    path: "/de/inspiration/editierung",
    component: () => import("/home/aquarist/www/prod/pages/inspiration/edit/index.vue")
  },
  {
    name: "inspiration___cs",
    path: "/inspirace",
    meta: indexxZl64Y2VyUMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/inspiration/index.vue")
  },
  {
    name: "inspiration___en",
    path: "/en/inspiration",
    meta: indexxZl64Y2VyUMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/inspiration/index.vue")
  },
  {
    name: "inspiration___pl",
    path: "/pl/inspiracja",
    meta: indexxZl64Y2VyUMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/inspiration/index.vue")
  },
  {
    name: "inspiration___sk",
    path: "/sk/inspiracia",
    meta: indexxZl64Y2VyUMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/inspiration/index.vue")
  },
  {
    name: "inspiration___de",
    path: "/de/inspiration",
    meta: indexxZl64Y2VyUMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/inspiration/index.vue")
  },
  {
    name: "inspiration-type-slug___cs",
    path: "/inspirace/typu/:slug()",
    meta: _91slug_93MJSAER1vFZMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/inspiration/type/[slug].vue")
  },
  {
    name: "inspiration-type-slug___en",
    path: "/en/inspiration/type/:slug()",
    meta: _91slug_93MJSAER1vFZMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/inspiration/type/[slug].vue")
  },
  {
    name: "inspiration-type-slug___pl",
    path: "/pl/inspiracja/typ/:slug()",
    meta: _91slug_93MJSAER1vFZMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/inspiration/type/[slug].vue")
  },
  {
    name: "inspiration-type-slug___sk",
    path: "/sk/inspiracia/typu/:slug()",
    meta: _91slug_93MJSAER1vFZMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/inspiration/type/[slug].vue")
  },
  {
    name: "inspiration-type-slug___de",
    path: "/de/inspiration/art/:slug()",
    meta: _91slug_93MJSAER1vFZMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/inspiration/type/[slug].vue")
  },
  {
    name: "inspiration-type___cs",
    path: "/inspirace/typu",
    meta: index8L8QSzB42lMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/inspiration/type/index.vue")
  },
  {
    name: "inspiration-type___en",
    path: "/en/inspiration/type",
    meta: index8L8QSzB42lMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/inspiration/type/index.vue")
  },
  {
    name: "inspiration-type___pl",
    path: "/pl/inspiracja/typ",
    meta: index8L8QSzB42lMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/inspiration/type/index.vue")
  },
  {
    name: "inspiration-type___sk",
    path: "/sk/inspiracia/typu",
    meta: index8L8QSzB42lMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/inspiration/type/index.vue")
  },
  {
    name: "inspiration-type___de",
    path: "/de/inspiration/art",
    meta: index8L8QSzB42lMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/inspiration/type/index.vue")
  },
  {
    name: "login___cs",
    path: "/prihlaseni",
    component: () => import("/home/aquarist/www/prod/pages/login.vue")
  },
  {
    name: "login___en",
    path: "/en/login",
    component: () => import("/home/aquarist/www/prod/pages/login.vue")
  },
  {
    name: "login___pl",
    path: "/pl/logowanie",
    component: () => import("/home/aquarist/www/prod/pages/login.vue")
  },
  {
    name: "login___sk",
    path: "/sk/prihlasenie",
    component: () => import("/home/aquarist/www/prod/pages/login.vue")
  },
  {
    name: "login___de",
    path: "/de/einloggen",
    component: () => import("/home/aquarist/www/prod/pages/login.vue")
  },
  {
    name: "logout___cs",
    path: "/odhlaseni",
    component: () => import("/home/aquarist/www/prod/pages/logout.vue")
  },
  {
    name: "logout___en",
    path: "/en/logout",
    component: () => import("/home/aquarist/www/prod/pages/logout.vue")
  },
  {
    name: "logout___pl",
    path: "/pl/wylogowanie",
    component: () => import("/home/aquarist/www/prod/pages/logout.vue")
  },
  {
    name: "logout___sk",
    path: "/sk/odhlasenie",
    component: () => import("/home/aquarist/www/prod/pages/logout.vue")
  },
  {
    name: "logout___de",
    path: "/de/ausloggen",
    component: () => import("/home/aquarist/www/prod/pages/logout.vue")
  },
  {
    name: "news-slug___cs",
    path: "/novinky/:slug()",
    component: () => import("/home/aquarist/www/prod/pages/news/[slug].vue")
  },
  {
    name: "news-slug___en",
    path: "/en/news/:slug()",
    component: () => import("/home/aquarist/www/prod/pages/news/[slug].vue")
  },
  {
    name: "news-slug___pl",
    path: "/pl/nowosc/:slug()",
    component: () => import("/home/aquarist/www/prod/pages/news/[slug].vue")
  },
  {
    name: "news-slug___sk",
    path: "/sk/novinky/:slug()",
    component: () => import("/home/aquarist/www/prod/pages/news/[slug].vue")
  },
  {
    name: "news-slug___de",
    path: "/de/nachrichten/:slug()",
    component: () => import("/home/aquarist/www/prod/pages/news/[slug].vue")
  },
  {
    name: "news-about-slug___cs",
    path: "/novinky/tema/:slug()",
    meta: _91slug_93tJPGhIXm5OMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/news/about/[slug].vue")
  },
  {
    name: "news-about-slug___en",
    path: "/en/news/about/:slug()",
    meta: _91slug_93tJPGhIXm5OMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/news/about/[slug].vue")
  },
  {
    name: "news-about-slug___pl",
    path: "/pl/nowosc/temat/:slug()",
    meta: _91slug_93tJPGhIXm5OMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/news/about/[slug].vue")
  },
  {
    name: "news-about-slug___sk",
    path: "/sk/novinky/tema/:slug()",
    meta: _91slug_93tJPGhIXm5OMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/news/about/[slug].vue")
  },
  {
    name: "news-about-slug___de",
    path: "/de/nachrichten/thema/:slug()",
    meta: _91slug_93tJPGhIXm5OMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/news/about/[slug].vue")
  },
  {
    name: "news-about___cs",
    path: "/novinky/tema",
    meta: indexAkACqhDmWaMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/news/about/index.vue")
  },
  {
    name: "news-about___en",
    path: "/en/news/about",
    meta: indexAkACqhDmWaMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/news/about/index.vue")
  },
  {
    name: "news-about___pl",
    path: "/pl/nowosc/temat",
    meta: indexAkACqhDmWaMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/news/about/index.vue")
  },
  {
    name: "news-about___sk",
    path: "/sk/novinky/tema",
    meta: indexAkACqhDmWaMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/news/about/index.vue")
  },
  {
    name: "news-about___de",
    path: "/de/nachrichten/thema",
    meta: indexAkACqhDmWaMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/news/about/index.vue")
  },
  {
    name: "news___cs",
    path: "/novinky",
    meta: indexaps9REVB6uMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/news/index.vue")
  },
  {
    name: "news___en",
    path: "/en/news",
    meta: indexaps9REVB6uMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/news/index.vue")
  },
  {
    name: "news___pl",
    path: "/pl/nowosc",
    meta: indexaps9REVB6uMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/news/index.vue")
  },
  {
    name: "news___sk",
    path: "/sk/novinky",
    meta: indexaps9REVB6uMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/news/index.vue")
  },
  {
    name: "news___de",
    path: "/de/nachrichten",
    meta: indexaps9REVB6uMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/news/index.vue")
  },
  {
    name: "offers-slug___cs",
    path: "/nabidky/:slug()",
    meta: _91slug_93ETNZaqS9XkMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/offers/[slug].vue")
  },
  {
    name: "offers-slug___en",
    path: "/en/offers/:slug()",
    meta: _91slug_93ETNZaqS9XkMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/offers/[slug].vue")
  },
  {
    name: "offers-slug___pl",
    path: "/pl/oferty/:slug()",
    meta: _91slug_93ETNZaqS9XkMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/offers/[slug].vue")
  },
  {
    name: "offers-slug___sk",
    path: "/sk/ponuky/:slug()",
    meta: _91slug_93ETNZaqS9XkMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/offers/[slug].vue")
  },
  {
    name: "offers-slug___de",
    path: "/de/angeboten/:slug()",
    meta: _91slug_93ETNZaqS9XkMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/offers/[slug].vue")
  },
  {
    name: "offers___cs",
    path: "/nabidky",
    meta: indexI7GXPuEPptMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/offers/index.vue")
  },
  {
    name: "offers___en",
    path: "/en/offers",
    meta: indexI7GXPuEPptMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/offers/index.vue")
  },
  {
    name: "offers___pl",
    path: "/pl/oferty",
    meta: indexI7GXPuEPptMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/offers/index.vue")
  },
  {
    name: "offers___sk",
    path: "/sk/ponuky",
    meta: indexI7GXPuEPptMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/offers/index.vue")
  },
  {
    name: "offers___de",
    path: "/de/angeboten",
    meta: indexI7GXPuEPptMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/offers/index.vue")
  },
  {
    name: "orders-cart_id___cs",
    path: "/nakupy/:cart_id()",
    component: () => import("/home/aquarist/www/prod/pages/orders/[cart_id].vue")
  },
  {
    name: "orders-cart_id___en",
    path: "/en/orders/:cart_id()",
    component: () => import("/home/aquarist/www/prod/pages/orders/[cart_id].vue")
  },
  {
    name: "orders-cart_id___pl",
    path: "/pl/kupowanie/:cart_id()",
    component: () => import("/home/aquarist/www/prod/pages/orders/[cart_id].vue")
  },
  {
    name: "orders-cart_id___sk",
    path: "/sk/nakupy/:cart_id()",
    component: () => import("/home/aquarist/www/prod/pages/orders/[cart_id].vue")
  },
  {
    name: "orders-cart_id___de",
    path: "/de/kaufen/:cart_id()",
    component: () => import("/home/aquarist/www/prod/pages/orders/[cart_id].vue")
  },
  {
    name: "orders___cs",
    path: "/nakupy",
    component: () => import("/home/aquarist/www/prod/pages/orders/index.vue")
  },
  {
    name: "orders___en",
    path: "/en/orders",
    component: () => import("/home/aquarist/www/prod/pages/orders/index.vue")
  },
  {
    name: "orders___pl",
    path: "/pl/kupowanie",
    component: () => import("/home/aquarist/www/prod/pages/orders/index.vue")
  },
  {
    name: "orders___sk",
    path: "/sk/nakupy",
    component: () => import("/home/aquarist/www/prod/pages/orders/index.vue")
  },
  {
    name: "orders___de",
    path: "/de/kaufen",
    component: () => import("/home/aquarist/www/prod/pages/orders/index.vue")
  },
  {
    name: "privacy___cs",
    path: "/gdpr",
    component: () => import("/home/aquarist/www/prod/pages/privacy.vue")
  },
  {
    name: "privacy___en",
    path: "/en/privacy",
    component: () => import("/home/aquarist/www/prod/pages/privacy.vue")
  },
  {
    name: "privacy___pl",
    path: "/pl/gdpr",
    component: () => import("/home/aquarist/www/prod/pages/privacy.vue")
  },
  {
    name: "privacy___sk",
    path: "/sk/gdpr",
    component: () => import("/home/aquarist/www/prod/pages/privacy.vue")
  },
  {
    name: "privacy___de",
    path: "/de/gdpr",
    component: () => import("/home/aquarist/www/prod/pages/privacy.vue")
  },
  {
    name: "product-slug___cs",
    path: "/produkt/:slug()",
    component: () => import("/home/aquarist/www/prod/pages/product/[slug].vue")
  },
  {
    name: "product-slug___en",
    path: "/en/product/:slug()",
    component: () => import("/home/aquarist/www/prod/pages/product/[slug].vue")
  },
  {
    name: "product-slug___pl",
    path: "/pl/produkt/:slug()",
    component: () => import("/home/aquarist/www/prod/pages/product/[slug].vue")
  },
  {
    name: "product-slug___sk",
    path: "/sk/produkt/:slug()",
    component: () => import("/home/aquarist/www/prod/pages/product/[slug].vue")
  },
  {
    name: "product-slug___de",
    path: "/de/produkt/:slug()",
    component: () => import("/home/aquarist/www/prod/pages/product/[slug].vue")
  },
  {
    name: "product___cs",
    path: "/produkt",
    component: () => import("/home/aquarist/www/prod/pages/product/index.vue")
  },
  {
    name: "product___en",
    path: "/en/product",
    component: () => import("/home/aquarist/www/prod/pages/product/index.vue")
  },
  {
    name: "product___pl",
    path: "/pl/produkt",
    component: () => import("/home/aquarist/www/prod/pages/product/index.vue")
  },
  {
    name: "product___sk",
    path: "/sk/produkt",
    component: () => import("/home/aquarist/www/prod/pages/product/index.vue")
  },
  {
    name: "product___de",
    path: "/de/produkt",
    component: () => import("/home/aquarist/www/prod/pages/product/index.vue")
  },
  {
    name: "profile___cs",
    path: "/profil",
    component: () => import("/home/aquarist/www/prod/pages/profile.vue")
  },
  {
    name: "profile___en",
    path: "/en/profile",
    component: () => import("/home/aquarist/www/prod/pages/profile.vue")
  },
  {
    name: "profile___pl",
    path: "/pl/profil",
    component: () => import("/home/aquarist/www/prod/pages/profile.vue")
  },
  {
    name: "profile___sk",
    path: "/sk/profil",
    component: () => import("/home/aquarist/www/prod/pages/profile.vue")
  },
  {
    name: "profile___de",
    path: "/de/profil",
    component: () => import("/home/aquarist/www/prod/pages/profile.vue")
  },
  {
    name: "reference-slug___cs",
    path: "/reference/:slug()",
    component: () => import("/home/aquarist/www/prod/pages/reference/[slug].vue")
  },
  {
    name: "reference-slug___en",
    path: "/en/reference/:slug()",
    component: () => import("/home/aquarist/www/prod/pages/reference/[slug].vue")
  },
  {
    name: "reference-slug___pl",
    path: "/pl/referencja/:slug()",
    component: () => import("/home/aquarist/www/prod/pages/reference/[slug].vue")
  },
  {
    name: "reference-slug___sk",
    path: "/sk/referencia/:slug()",
    component: () => import("/home/aquarist/www/prod/pages/reference/[slug].vue")
  },
  {
    name: "reference-slug___de",
    path: "/de/referenz/:slug()",
    component: () => import("/home/aquarist/www/prod/pages/reference/[slug].vue")
  },
  {
    name: "reference___cs",
    path: "/reference",
    component: () => import("/home/aquarist/www/prod/pages/reference/index.vue")
  },
  {
    name: "reference___en",
    path: "/en/reference",
    component: () => import("/home/aquarist/www/prod/pages/reference/index.vue")
  },
  {
    name: "reference___pl",
    path: "/pl/referencja",
    component: () => import("/home/aquarist/www/prod/pages/reference/index.vue")
  },
  {
    name: "reference___sk",
    path: "/sk/referencia",
    component: () => import("/home/aquarist/www/prod/pages/reference/index.vue")
  },
  {
    name: "reference___de",
    path: "/de/referenz",
    component: () => import("/home/aquarist/www/prod/pages/reference/index.vue")
  },
  {
    name: "register___cs",
    path: "/registrace",
    component: () => import("/home/aquarist/www/prod/pages/register.vue")
  },
  {
    name: "register___en",
    path: "/en/registration",
    component: () => import("/home/aquarist/www/prod/pages/register.vue")
  },
  {
    name: "register___pl",
    path: "/pl/rejestracja",
    component: () => import("/home/aquarist/www/prod/pages/register.vue")
  },
  {
    name: "register___sk",
    path: "/sk/registracia",
    component: () => import("/home/aquarist/www/prod/pages/register.vue")
  },
  {
    name: "register___de",
    path: "/de/anmeldung",
    component: () => import("/home/aquarist/www/prod/pages/register.vue")
  },
  {
    name: "requests-slug___cs",
    path: "/poptavky/:slug()",
    meta: _91slug_934MUTTUtmLDMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/requests/[slug].vue")
  },
  {
    name: "requests-slug___en",
    path: "/en/requests/:slug()",
    meta: _91slug_934MUTTUtmLDMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/requests/[slug].vue")
  },
  {
    name: "requests-slug___pl",
    path: "/pl/popyt/:slug()",
    meta: _91slug_934MUTTUtmLDMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/requests/[slug].vue")
  },
  {
    name: "requests-slug___sk",
    path: "/sk/dopyty/:slug()",
    meta: _91slug_934MUTTUtmLDMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/requests/[slug].vue")
  },
  {
    name: "requests-slug___de",
    path: "/de/nachfragen/:slug()",
    meta: _91slug_934MUTTUtmLDMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/requests/[slug].vue")
  },
  {
    name: "requests___cs",
    path: "/poptavky",
    meta: indexlKgoQvJ2htMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/requests/index.vue")
  },
  {
    name: "requests___en",
    path: "/en/requests",
    meta: indexlKgoQvJ2htMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/requests/index.vue")
  },
  {
    name: "requests___pl",
    path: "/pl/popyt",
    meta: indexlKgoQvJ2htMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/requests/index.vue")
  },
  {
    name: "requests___sk",
    path: "/sk/dopyty",
    meta: indexlKgoQvJ2htMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/requests/index.vue")
  },
  {
    name: "requests___de",
    path: "/de/nachfragen",
    meta: indexlKgoQvJ2htMeta || {},
    component: () => import("/home/aquarist/www/prod/pages/requests/index.vue")
  },
  {
    name: "reset-password-hash___cs",
    path: "/reset-hesla/:hash()",
    component: () => import("/home/aquarist/www/prod/pages/reset-password/[hash].vue")
  },
  {
    name: "reset-password-hash___en",
    path: "/en/reset-password/:hash()",
    component: () => import("/home/aquarist/www/prod/pages/reset-password/[hash].vue")
  },
  {
    name: "reset-password-hash___pl",
    path: "/pl/reset-hasla/:hash()",
    component: () => import("/home/aquarist/www/prod/pages/reset-password/[hash].vue")
  },
  {
    name: "reset-password-hash___sk",
    path: "/sk/reset-hesla/:hash()",
    component: () => import("/home/aquarist/www/prod/pages/reset-password/[hash].vue")
  },
  {
    name: "reset-password-hash___de",
    path: "/de/passwort-reset/:hash()",
    component: () => import("/home/aquarist/www/prod/pages/reset-password/[hash].vue")
  },
  {
    name: "reset-password___cs",
    path: "/reset-hesla",
    component: () => import("/home/aquarist/www/prod/pages/reset-password/index.vue")
  },
  {
    name: "reset-password___en",
    path: "/en/reset-password",
    component: () => import("/home/aquarist/www/prod/pages/reset-password/index.vue")
  },
  {
    name: "reset-password___pl",
    path: "/pl/reset-hasla",
    component: () => import("/home/aquarist/www/prod/pages/reset-password/index.vue")
  },
  {
    name: "reset-password___sk",
    path: "/sk/reset-hesla",
    component: () => import("/home/aquarist/www/prod/pages/reset-password/index.vue")
  },
  {
    name: "reset-password___de",
    path: "/de/passwort-reset",
    component: () => import("/home/aquarist/www/prod/pages/reset-password/index.vue")
  },
  {
    name: "rules___cs",
    path: "/pravidla",
    component: () => import("/home/aquarist/www/prod/pages/rules.vue")
  },
  {
    name: "rules___en",
    path: "/en/rules",
    component: () => import("/home/aquarist/www/prod/pages/rules.vue")
  },
  {
    name: "rules___pl",
    path: "/pl/zasady",
    component: () => import("/home/aquarist/www/prod/pages/rules.vue")
  },
  {
    name: "rules___sk",
    path: "/sk/pravidla",
    component: () => import("/home/aquarist/www/prod/pages/rules.vue")
  },
  {
    name: "rules___de",
    path: "/de/nutzungsregeln",
    component: () => import("/home/aquarist/www/prod/pages/rules.vue")
  },
  {
    name: "sales-cart_id___cs",
    path: "/prodeje/:cart_id()",
    component: () => import("/home/aquarist/www/prod/pages/sales/[cart_id].vue")
  },
  {
    name: "sales-cart_id___en",
    path: "/en/sales/:cart_id()",
    component: () => import("/home/aquarist/www/prod/pages/sales/[cart_id].vue")
  },
  {
    name: "sales-cart_id___pl",
    path: "/pl/sprzedaze/:cart_id()",
    component: () => import("/home/aquarist/www/prod/pages/sales/[cart_id].vue")
  },
  {
    name: "sales-cart_id___sk",
    path: "/sk/predaje/:cart_id()",
    component: () => import("/home/aquarist/www/prod/pages/sales/[cart_id].vue")
  },
  {
    name: "sales-cart_id___de",
    path: "/de/verkaufe/:cart_id()",
    component: () => import("/home/aquarist/www/prod/pages/sales/[cart_id].vue")
  },
  {
    name: "sales___cs",
    path: "/prodeje",
    component: () => import("/home/aquarist/www/prod/pages/sales/index.vue")
  },
  {
    name: "sales___en",
    path: "/en/sales",
    component: () => import("/home/aquarist/www/prod/pages/sales/index.vue")
  },
  {
    name: "sales___pl",
    path: "/pl/sprzedaze",
    component: () => import("/home/aquarist/www/prod/pages/sales/index.vue")
  },
  {
    name: "sales___sk",
    path: "/sk/predaje",
    component: () => import("/home/aquarist/www/prod/pages/sales/index.vue")
  },
  {
    name: "sales___de",
    path: "/de/verkaufe",
    component: () => import("/home/aquarist/www/prod/pages/sales/index.vue")
  },
  {
    name: "settings___cs",
    path: "/nastaveni",
    component: () => import("/home/aquarist/www/prod/pages/settings/index.vue")
  },
  {
    name: "settings___en",
    path: "/en/settings",
    component: () => import("/home/aquarist/www/prod/pages/settings/index.vue")
  },
  {
    name: "settings___pl",
    path: "/pl/ustawienia",
    component: () => import("/home/aquarist/www/prod/pages/settings/index.vue")
  },
  {
    name: "settings___sk",
    path: "/sk/nastavenie",
    component: () => import("/home/aquarist/www/prod/pages/settings/index.vue")
  },
  {
    name: "settings___de",
    path: "/de/einstellungen",
    component: () => import("/home/aquarist/www/prod/pages/settings/index.vue")
  },
  {
    name: "settings-notifications___cs",
    path: "/nastaveni/notifikace",
    component: () => import("/home/aquarist/www/prod/pages/settings/notifications.vue")
  },
  {
    name: "settings-notifications___en",
    path: "/en/settings/notifications",
    component: () => import("/home/aquarist/www/prod/pages/settings/notifications.vue")
  },
  {
    name: "settings-notifications___pl",
    path: "/pl/ustawienia/powiadomien",
    component: () => import("/home/aquarist/www/prod/pages/settings/notifications.vue")
  },
  {
    name: "settings-notifications___sk",
    path: "/sk/nastavenie/notifikacie",
    component: () => import("/home/aquarist/www/prod/pages/settings/notifications.vue")
  },
  {
    name: "settings-notifications___de",
    path: "/de/einstellungen/benachrichtigung",
    component: () => import("/home/aquarist/www/prod/pages/settings/notifications.vue")
  },
  {
    name: "settings-watchdog-watchdog_id___cs",
    path: "/nastaveni/hlidaci-pes/:watchdog_id()",
    component: () => import("/home/aquarist/www/prod/pages/settings/watchdog/[watchdog_id].vue")
  },
  {
    name: "settings-watchdog-watchdog_id___en",
    path: "/en/settings/watchdog/:watchdog_id()",
    component: () => import("/home/aquarist/www/prod/pages/settings/watchdog/[watchdog_id].vue")
  },
  {
    name: "settings-watchdog-watchdog_id___pl",
    path: "/pl/ustawienia/pies-podworzowy/:watchdog_id()",
    component: () => import("/home/aquarist/www/prod/pages/settings/watchdog/[watchdog_id].vue")
  },
  {
    name: "settings-watchdog-watchdog_id___sk",
    path: "/sk/nastavenie/strazny-pes/:watchdog_id()",
    component: () => import("/home/aquarist/www/prod/pages/settings/watchdog/[watchdog_id].vue")
  },
  {
    name: "settings-watchdog-watchdog_id___de",
    path: "/de/einstellungen/wachhund/:watchdog_id()",
    component: () => import("/home/aquarist/www/prod/pages/settings/watchdog/[watchdog_id].vue")
  },
  {
    name: "settings-watchdog___cs",
    path: "/nastaveni/hlidaci-pes",
    component: () => import("/home/aquarist/www/prod/pages/settings/watchdog/index.vue")
  },
  {
    name: "settings-watchdog___en",
    path: "/en/settings/watchdog",
    component: () => import("/home/aquarist/www/prod/pages/settings/watchdog/index.vue")
  },
  {
    name: "settings-watchdog___pl",
    path: "/pl/ustawienia/pies-podworzowy",
    component: () => import("/home/aquarist/www/prod/pages/settings/watchdog/index.vue")
  },
  {
    name: "settings-watchdog___sk",
    path: "/sk/nastavenie/strazny-pes",
    component: () => import("/home/aquarist/www/prod/pages/settings/watchdog/index.vue")
  },
  {
    name: "settings-watchdog___de",
    path: "/de/einstellungen/wachhund",
    component: () => import("/home/aquarist/www/prod/pages/settings/watchdog/index.vue")
  },
  {
    name: "supplier-supplier_id___cs",
    path: "/prodejce/:supplier_id()",
    component: () => import("/home/aquarist/www/prod/pages/supplier/[supplier_id].vue")
  },
  {
    name: "supplier-supplier_id___en",
    path: "/en/supplier/:supplier_id()",
    component: () => import("/home/aquarist/www/prod/pages/supplier/[supplier_id].vue")
  },
  {
    name: "supplier-supplier_id___pl",
    path: "/pl/sprzedawca/:supplier_id()",
    component: () => import("/home/aquarist/www/prod/pages/supplier/[supplier_id].vue")
  },
  {
    name: "supplier-supplier_id___sk",
    path: "/sk/predajca/:supplier_id()",
    component: () => import("/home/aquarist/www/prod/pages/supplier/[supplier_id].vue")
  },
  {
    name: "supplier-supplier_id___de",
    path: "/de/anbieter/:supplier_id()",
    component: () => import("/home/aquarist/www/prod/pages/supplier/[supplier_id].vue")
  },
  {
    name: "supplier___cs",
    path: "/prodejce",
    component: () => import("/home/aquarist/www/prod/pages/supplier/index.vue")
  },
  {
    name: "supplier___en",
    path: "/en/supplier",
    component: () => import("/home/aquarist/www/prod/pages/supplier/index.vue")
  },
  {
    name: "supplier___pl",
    path: "/pl/sprzedawca",
    component: () => import("/home/aquarist/www/prod/pages/supplier/index.vue")
  },
  {
    name: "supplier___sk",
    path: "/sk/predajca",
    component: () => import("/home/aquarist/www/prod/pages/supplier/index.vue")
  },
  {
    name: "supplier___de",
    path: "/de/anbieter",
    component: () => import("/home/aquarist/www/prod/pages/supplier/index.vue")
  },
  {
    name: "terms___cs",
    path: "/podminky",
    component: () => import("/home/aquarist/www/prod/pages/terms.vue")
  },
  {
    name: "terms___en",
    path: "/en/terms",
    component: () => import("/home/aquarist/www/prod/pages/terms.vue")
  },
  {
    name: "terms___pl",
    path: "/pl/warunki",
    component: () => import("/home/aquarist/www/prod/pages/terms.vue")
  },
  {
    name: "terms___sk",
    path: "/sk/podmienky",
    component: () => import("/home/aquarist/www/prod/pages/terms.vue")
  },
  {
    name: "terms___de",
    path: "/de/bedingungen",
    component: () => import("/home/aquarist/www/prod/pages/terms.vue")
  },
  {
    name: "unfinished-cart_id___cs",
    path: "/cekajici/:cart_id()",
    component: () => import("/home/aquarist/www/prod/pages/unfinished/[cart_id].vue")
  },
  {
    name: "unfinished-cart_id___en",
    path: "/en/unfinished/:cart_id()",
    component: () => import("/home/aquarist/www/prod/pages/unfinished/[cart_id].vue")
  },
  {
    name: "unfinished-cart_id___pl",
    path: "/pl/czekajac/:cart_id()",
    component: () => import("/home/aquarist/www/prod/pages/unfinished/[cart_id].vue")
  },
  {
    name: "unfinished-cart_id___sk",
    path: "/sk/cakajuci/:cart_id()",
    component: () => import("/home/aquarist/www/prod/pages/unfinished/[cart_id].vue")
  },
  {
    name: "unfinished-cart_id___de",
    path: "/de/warten/:cart_id()",
    component: () => import("/home/aquarist/www/prod/pages/unfinished/[cart_id].vue")
  },
  {
    name: "unfinished___cs",
    path: "/cekajici",
    component: () => import("/home/aquarist/www/prod/pages/unfinished/index.vue")
  },
  {
    name: "unfinished___en",
    path: "/en/unfinished",
    component: () => import("/home/aquarist/www/prod/pages/unfinished/index.vue")
  },
  {
    name: "unfinished___pl",
    path: "/pl/czekajac",
    component: () => import("/home/aquarist/www/prod/pages/unfinished/index.vue")
  },
  {
    name: "unfinished___sk",
    path: "/sk/cakajuce",
    component: () => import("/home/aquarist/www/prod/pages/unfinished/index.vue")
  },
  {
    name: "unfinished___de",
    path: "/de/unfertig",
    component: () => import("/home/aquarist/www/prod/pages/unfinished/index.vue")
  },
  {
    name: "why-with-us___cs",
    path: "/proc-inzerovat-u-nas",
    component: () => import("/home/aquarist/www/prod/pages/why-with-us.vue")
  },
  {
    name: "why-with-us___en",
    path: "/en/why-advertise-with-us",
    component: () => import("/home/aquarist/www/prod/pages/why-with-us.vue")
  },
  {
    name: "why-with-us___pl",
    path: "/pl/dlaczego-warto-sie-u-nas-reklamowac",
    component: () => import("/home/aquarist/www/prod/pages/why-with-us.vue")
  },
  {
    name: "why-with-us___sk",
    path: "/sk/preco-inzerovat-u-nas",
    component: () => import("/home/aquarist/www/prod/pages/why-with-us.vue")
  },
  {
    name: "why-with-us___de",
    path: "/de/warum-bei-uns-werben",
    component: () => import("/home/aquarist/www/prod/pages/why-with-us.vue")
  }
]