<template>
    <component :is="map[icon]" />
</template>

<script setup>
import FacebookIcon from "@components/icons/facebook";
import InstagramIcon from "@components/icons/instagram";
import TwitterIcon from "@components/icons/twitter";
import YouTubeIcon from "@components/icons/youtube";

defineProps({
    icon: {
        type: String,
        required: true
    }
})

const map = {
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    YouTubeIcon
}

</script>
