<template>
  <div>
    <Swiper
      id="productGallery"
      :modules="[Thumbs]"
      :thumbs="{ swiper: thumbsSwiper }"
      :space-between="0"
      :slides-per-view="1"
    >
      <SwiperSlide
        v-for="(item, idx) in gallery"
        :key="`product-gallery-${item.id}`"
        class="flex items-center justify-center"
      >
        <Image
          :src="item?.url || siteSettings.product.placeholderImage[config.public.layoutDesign]"
          :alt="item?.description"
          :width="768"
          :height="576"
          sizes="280px xs:588px sm:460px lg:588px"
          layout="fill"
          object-fit="cover"
          class="rounded"
          class-name="cursor-zoom-in"
          @click="() => showImage(idx)"
        />
      </SwiperSlide>
    </Swiper>

    <div class="w-full mt-4 mx-auto relative lg:pb-2">
      <Swiper
        id="productGalleryThumbs"
        :modules="[SwiperNavigation]"
        :space-between="20"
        :watch-slides-visibility="true"
        :watch-slides-progress="true"
        :breakpoints="galleryCarouselBreakpoints"
        :navigation="{ prevEl: '.product-gallery-prev', nextEl: '.product-gallery-next' }"
        @swiper="setThumbsSwiper"
      >
        <SwiperSlide
          v-for="(item) in gallery"
          :key="`product-thumb-gallery-${item.id}`"
          class="flex items-center justify-center cursor-pointer rounded overflow-hidden border border-border-200 border-opacity-75 hover:opacity-75 !w-36 !h-24"
        >
          <Image
            :src="item?.url || siteSettings.product.placeholderImage[config.public.layoutDesign]"
            :alt="item?.description"
            :width="142"
            :height="96"
            sizes="142px"
            object-fit="cover"
          />
        </SwiperSlide>
      </Swiper>
      <div class="product-gallery-prev cursor-pointer absolute top-2/4 -start-4 md:-start-5 z-10 -mt-4 md:-mt-5 w-8 h-8 md:w-9 md:h-9 rounded-full bg-light shadow-xl border border-border-200 border-opacity-70 flex items-center justify-center text-bolder transition-all duration-200 hover:bg-gray-100">
        <ChevronLeft class="w-4 h-4" />
      </div>
      <div class="product-gallery-next cursor-pointer absolute top-2/4 -end-4 md:-end-5 z-10 -mt-4 md:-mt-5 w-8 h-8 md:w-9 md:h-9 rounded-full bg-light shadow-xl border border-border-200 border-opacity-70 flex items-center justify-center text-bolder transition-all duration-200 hover:bg-gray-100">
        <ChevronRight class="w-4 h-4" />
      </div>
    </div>
  </div>
</template>

<script setup>
import _ from 'lodash-es';
import GLightbox from 'glightbox-ssr';
import { Thumbs } from 'swiper/modules';
import { siteSettings } from "@settings/site.settings";
import Image from "@components/ui/image";
import ChevronLeft from "@components/icons/chevron-left";
import ChevronRight from "@components/icons/chevron-right";

const props = defineProps({
  gallery: {
    type: Array,
    default: () => []
  }
});

const config = useRuntimeConfig();

const thumbsSwiper = ref(null);
const setThumbsSwiper = (swiper) => {
  thumbsSwiper.value = swiper;
};

const galleryCarouselBreakpoints = {
  320: {
    slidesPerView: 2,
  },
  480: {
    slidesPerView: 3,
  },
  640: {
    slidesPerView: 3,
  },
  800: {
    slidesPerView: 3,
  },
};

const lightbox = GLightbox({ touchNavigation: true, selector: null });

const mapElements = (value) => _.map(_.filter(value, image => image?.url), image => (_.assign({}, {
  href: image.url,
  type: 'image'
}, image?.description ? { description: image?.description } : {} )));

onMounted(() => {
  lightbox.setElements(mapElements(props?.gallery || []));
});

const showImage = (idx) => lightbox.openAt(idx);

</script>
