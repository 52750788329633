<template>
  <div :class="[ 'flex flex-col items-center justify-center', loading ? 'h-126' : '', loading || variants?.length ? 'my-8 sm:my-15 p-5' : '' ]">
    <div v-if="variants?.length">
        <div :class="[ 'flex flex-col md:flex-row items-center justify-between w-full mb-2.5 py-4', size === 'page' ? 'md:w-156 lg:w-239 2xl:w-322' : 'md:w-156 2xl:w-239' ]">
            <div class="flex flex-col mb-5 sm:mb-5">
                <h2 class="text-2xl font-bold text-center sm:text-left">
                    {{ $t('text-supplier-ads') }}
                </h2>
            </div>
            <div class="flex items-center justify-end space-x-5 flex-shrink-0 ml-5">
                <Button
                    variant="outline"
                    size="menu"
                    :on-click="handleShowMore"
                >
                    <span class="text-base">{{ $t('text-show-all') }}</span>
                </Button>
                <div class="flex items-center justify-end space-x-2">
                    <MenuButton
                        variant="base"
                        :disabled="page === 0"
                        :aria-label="$t('text-previous')"
                        :on-click="handlePrev"
                    >
                        <ChevronLeft class="w-3 h-3" />
                    </MenuButton>
                    <MenuButton
                        variant="base"
                        :disabled="Math.floor(pagination.itemCount / gridSize.width.value / 2) === page"
                        :aria-label="$t('text-next')"
                        :on-click="handleNext"
                    >
                        <ChevronRight class="w-3 h-3" />
                    </MenuButton>
                </div>
            </div>
        </div>
        <div v-if="loading || !isMounted" class="w-full flex justify-center">
          <div :class="[ 'w-full sm:w-fit gap-x-9 gap-y-2.5 sm:gap-y-9', size === 'page' ? 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4' : 'grid md:grid-cols-2 2xl:grid-cols-3' ]">
            <ProductFeedLoader :limit="isMounted ? gridWidth : 1" />
          </div>
        </div>
        <div v-else class="w-full flex justify-center">
          <div :class="[ 'w-full sm:w-fit gap-x-9 gap-y-2.5 sm:gap-y-9', size === 'page' ? 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4' : 'grid md:grid-cols-2 2xl:grid-cols-3' ]">
            <RenderProductCard
                v-for="(variant, idx) in variants"
                :key="variant.id"
                :variant="variant"
                :class-name="isHidden(idx) ? 'hidden' : ''"
            />
          </div>
        </div>
    </div>
  </div>
</template>

<script setup>
import _ from 'lodash-es';
import { API_ENDPOINTS } from "@utils/api/endpoints";
import { ROUTES } from "@utils/routes";
import { PRODUCT_SUBTYPE } from "@utils/constants";
import { useAuthStore } from '@stores/auth'
import { useSelectedStore } from '@stores/selected'
import Button from "@components/ui/button";
import MenuButton from "@components/ui/menu-button";
import ChevronLeft from "@components/icons/chevron-left";
import ChevronRight from "@components/icons/chevron-right";
import ProductFeedLoader from "@components/ui/loaders/product-feed-loader";
import RenderProductCard from "@components/product/render-product-card";

const props = defineProps({
  exceptId: {
    type: Number,
    required: true,
  },
  supplier: {
    type: Object,
    default: () => { }
  },
  size: {
    type: String,
    default: 'page' //page, modal
  }
})

const { t } = useI18n();
const { $eventBus, $toast } = useNuxtApp();
const auth = useAuthStore();
const store = useSelectedStore();
const config = useRuntimeConfig();
const category = useState('route.category', () => config.public.layoutDesign);
const loading = ref(false);
const isMounted = ref(false);
const subtype = PRODUCT_SUBTYPE.supplier;
const page = ref(0);
const pages = useState(`products.${subtype}.${props.supplier?.id || ''}.pages.${category.value}`, () => []);
const pagination = useState(`products.${subtype}.${props.supplier?.id || ''}.pagination.${category.value}`, () => ({ page: 1, pageCount: 1, itemsPerPage: 12, itemCount: 0 }));
const gridSize = useGridSize();
const routeLang = useRouteLang();

const closeModal = () => {
    $eventBus.emit('modal:close');
}

const handleShowMore = async () => {
    closeModal();
    await navigatorTo(auth.isLoggedIn ? translatePath(ROUTES.CODE.SUPPLIER, slugify(props.supplier?.id, props.supplier?.name)) : translatePath(ROUTES.CODE.ADS));
}

const variants = computed(() => loading.value && !pages.value.length
  ? []
  : _.flatMap(pages.value, (page) => page?.items || [])
);

const gridWidth = computed(() => {
    if (props.size === 'page') {
        return gridSize.width.value;
    }
    return gridSize.width.value > 2 ? gridSize.width.value - 1 : gridSize.width.value;
});

const isHidden = (idx) => {
    return idx < page.value * gridWidth.value || idx >= (page.value + 1) * gridWidth.value;
};

const reload = async () => {
  loading.value = true;

  const { data, error, status } = await useApiFetch(API_ENDPOINTS.ESHOP_PRODUCT_VARIANT, {
    method: 'POST',
    params: {
      page: pagination.value.page,
      limit: pagination.value.itemsPerPage,
      locale: routeLang.locale.value
    },
    body: {
        idSupplier: props.supplier?.id,
        exceptIds: [ props.exceptId ]
    }
  });
  if (status.value === 'error') {
    if (import.meta.client && !auth.reloadTimeout) {
      $toast.error(t('error.product-detail.load'));
    }
    sentryCaptureEvent({
      message: 'supplier-ads-panel.reload',
      level: 'error',
      extra: { error: error.value }
    });
  } else {
    pages.value.push(data.value);
    pagination.value = data.value.pagination;
  }
  loading.value = false;
}

onServerPrefetch(async () => {
  pagination.value.page = 1;
  await reload();
});

const variantUpdate = (value) => {
  const variant = _.find(variants.value, { id: value.id });
  if (variant) {
    _.assignIn(variant, value);
    store.setLoadResetOthers();
  }
};

onMounted(async () => {
  isMounted.value = true;
  if (_.isEmpty(pages.value) || store.loadReset[subtype]) {
    store.setLoadReset(subtype, false);
  }
  if (import.meta.server || navigator?.onLine) {
    pages.value = [];
    pagination.value.page = 1;
    await reload();
  }
  $eventBus.on('variant:update', variantUpdate);
});

onUnmounted(() => {
  $eventBus.off('variant:update', variantUpdate);
});

const handlePrev = () => {
    page.value = Math.max(0, page.value - 1);
}

const handleNext = async () => {
    page.value = Math.min(Math.floor(pagination.value.itemCount / gridSize.width.value), page.value + 1);
    if (page.value * gridSize.width.value >= variants.value.length) {
        pagination.value.page++;
        await reload();
    }
}

</script>
