import { ROUTES } from "../utils/routes";

export type BannerType = {
  heading: string;
  subheading: string;
  image: string;
  routeCode?: string;
  metaType?: string;
};

export interface PageInfo {
  title: string;
  description: string;
  image: string;
  type: string;
  main: BannerType[];
  category: BannerType[];
  ads: BannerType[];
  myAds: BannerType[];
  offers: BannerType[];
  requests: BannerType[];
  auctions: BannerType[];
  favorites: BannerType[];
  inspiration: BannerType[];
  news: BannerType[];
  reference: BannerType[];
  profile: object[];
}

export type PageName = | "aquarist" | "pipeclub";

export const sitePages: Record<PageName, PageInfo> = {
  aquarist: {
    title: "ForAquarist.com",
    description: "Získejte parťáka pro vaši akvarijní rybku",
    image: "/banner/aquarist.jpg",
    type: "website",
    main: [
      {
        heading: "heading-main",
        subheading: "subheading-main",
        image: "/banner/aquarist.jpg",
        routeCode: ROUTES.CODE.ADS
      },
      {
        heading: "heading-main-2",
        subheading: "subheading-main-2",
        image: "/banner/aquarist2.jpg",
        routeCode: ROUTES.CODE.ADS
      },
      {
        heading: "heading-main-5",
        subheading: "subheading-main-5",
        image: "/banner/aquarist5.jpg",
        routeCode: ROUTES.CODE.NEWS
      }
    ],
    category: [
      {
        heading: "heading-category",
        subheading: "subheading-category",
        image: "/banner/aquarist2.jpg",
      }
    ],
    ads: [
      {
        heading: "heading-ads",
        subheading: "subheading-ads",
        image: "/banner/aquarist2.jpg",
        metaType: "product"
      }
    ],
    myAds: [
      {
        heading: "heading-my-ads",
        subheading: "subheading-my-ads",
        image: "/banner/aquarist.jpg",
      }
    ],
    offers: [
      {
        heading: "heading-offers",
        subheading: "subheading-offers",
        image: "/banner/aquarist2.jpg",
      }
    ],
    requests: [
      {
        heading: "heading-requests",
        subheading: "subheading-requests",
        image: "/banner/aquarist6.jpg",
      }
    ],
    auctions: [
      {
        heading: "heading-auctions",
        subheading: "subheading-auctions",
        image: "/banner/aquarist2.jpg",
      }
    ],
    favorites: [
      {
        heading: "heading-favorites",
        subheading: "subheading-favorites",
        image: "/banner/aquarist.jpg",
      }
    ],
    inspiration: [
      {
        heading: "heading-inspiration",
        subheading: "subheading-inspiration",
        image: "/banner/aquarist.jpg",
        metaType: "article"
      }
    ],
    news: [
      {
        heading: "heading-news",
        subheading: "subheading-news",
        image: "/banner/aquarist5.jpg",
        metaType: "article"
      }
    ],
    reference: [
      {
        heading: "heading-reference",
        subheading: "subheading-reference",
        image: "/banner/aquarist5.jpg",
        metaType: "article"
      }
    ],
    profile: [
      {
        type: "profile"
      }
    ]
  },
  pipeclub: {
    title: "PipeClub.eu",
    description: "Dýmky přímo od mistrů výrobců",
    image: "/banner/pipeclub.jpg",
    type: "website",
    main: [
      {
        heading: "heading-main",
        subheading: "subheading-main",
        image: "/banner/pipeclub.jpg",
        routeCode: ROUTES.CODE.ADS
      }
    ],
    category: [
      {
        heading: "heading-main",
        subheading: "subheading-main",
        image: "/banner/pipeclub.jpg",
      }
    ],
    ads: [
      {
        heading: "heading-main",
        subheading: "subheading-main",
        image: "/banner/pipeclub.jpg",
        metaType: "product"
      }
    ],
    myAds: [
      {
        heading: "heading-main",
        subheading: "subheading-main",
        image: "/banner/pipeclub.jpg",
      }
    ],
    offers: [
      {
        heading: "heading-main",
        subheading: "subheading-main",
        image: "/banner/pipeclub.jpg",
      }
    ],
    requests: [
      {
        heading: "heading-main",
        subheading: "subheading-main",
        image: "/banner/pipeclub.jpg",
      }
    ],
    auctions: [
      {
        heading: "heading-main",
        subheading: "subheading-main",
        image: "/banner/pipeclub.jpg",
      }
    ],
    favorites: [
      {
        heading: "heading-main",
        subheading: "subheading-main",
        image: "/banner/pipeclub.jpg",
      }
    ],
    inspiration: [
      {
        heading: "heading-main",
        subheading: "subheading-main",
        image: "/banner/pipeclub.jpg",
        metaType: "article"
      }
    ],
    news: [
      {
        heading: "heading-main",
        subheading: "subheading-main",
        image: "/banner/pipeclub.jpg",
        metaType: "article"
      }
    ],
    reference: [
      {
        heading: "heading-main",
        subheading: "subheading-main",
        image: "/banner/pipeclub.jpg",
        metaType: "article"
      }
    ],
    profile: [
      {
        type: "profile"
      }
    ]
  }
};
