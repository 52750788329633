export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1 maximum-scale=1"},{"name":"msapplication-TileColor","content":"#ffffff"},{"name":"theme-color","content":"#ef404a"},{"property":"og:title","content":"ForAquarist.com"},{"property":"og:description","content":"Získejte parťáka pro vaši akvarijní rybku"},{"property":"og:image","content":"/banner/aquarist.jpg"},{"property":"og:type","content":"website"}],"link":[{"rel":"icon","href":"/favicon-aquarist-32x32.png","sizes":"32x32","type":"image/png"},{"rel":"preconnect","href":"https://c.clarity.ms"},{"rel":"preconnect","href":"https://www.clarity.ms"},{"rel":"preconnect","href":"https://www.gstatic.com"},{"rel":"preconnect","href":"https://www.googletagmanager.com"},{"rel":"preconnect","href":"https://www.google.com"}],"style":[],"script":[],"noscript":[],"titleTemplate":"%s | ForAquarist.com"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"activeClass":"nuxt-link-active","trailingSlash":"append","componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = false