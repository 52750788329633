<template>
  <div class="w-full">
    <div class="product-gallery h-full relative mb-9">
      <div v-if="discount" class="rounded-full text-xs leading-6 font-semibold px-3 bg-yellow-500 text-light absolute top-4 end-4 z-10">
        {{ discount }}
      </div>
      <Carousel v-if="_.get(variant, 'images.length', 0) > 1" :gallery="variant.images" />
      <div v-else class="w-full h-full flex items-start justify-center text-light ">
        <Image
          :src="variant?.image?.url || _.get(variant, 'images.0.url', null) || siteSettings.product.placeholderImage[config.public.layoutDesign]"
          :alt="variant.name"
          :width="768"
          :height="576"
          sizes="280px xs:588px sm:460px lg:588px"
          object-fit="cover"
          class="rounded"
          :class-name="variant?.image?.url || _.get(variant, 'images.0.url', null) ? 'cursor-zoom-in bg-radial-gradient' : 'bg-radial-gradient'"
          @click="showImage"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import _ from 'lodash-es';
import GLightbox from 'glightbox-ssr';
import { siteSettings } from "@settings/site.settings";
import usePrice from "@utils/use-price";
import Image from "@components/ui/image";
import Carousel from "@components/ui/carousel";

const props = defineProps({
  variant: {
    type: Object,
    default: () => { }
  }
})

const config = useRuntimeConfig();

const discount = computed(() => props.variant?.value ? usePrice({
  amount: +props.variant.value.price,
  baseAmount: +props.variant.value.sale_price
}).discount : '');

const lightbox = GLightbox({ touchNavigation: true, selector: null });

const mapElements = (value) => _.map(_.filter(value, image => image?.url), image => ({
  href: image.url,
  type: 'image'
}));

onMounted(() => {
  const image = props.variant?.image || _.get(props.variant, 'images.0', null);
  lightbox.setElements(mapElements(image ? [ image ] : []));
});

const showImage = () => lightbox.open();

</script>
