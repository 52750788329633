<template>
  <div class="w-full pt-4 border-t border-primary-neutral">
    <button
      :class="[ 'flex items-center w-full text-start outline-none focus:outline-none focus:ring-0 hover:no-underline text-base font-bold', isDetail ? 'text-bolder' : 'text-heading']"
    >
      <div class="flex justify-between items-center w-full cursor-pointer" @click="toggleCollapse">
        <div>
          {{ $t('text-distance-from-location') }}
        </div>
        <div class="ms-auto text-heading">
          <MinusIcon v-if="isOpen" class="w-6 h-6" />
          <PlusIcon v-else class="w-6 h-6" />
        </div>
      </div>
    </button>
    <div v-if="isOpen" class="mt-2">
      <p v-if="error" :class="['my-2 text-xs', isDetail ? 'text-selected-detail' : 'text-selected-item']">
          {{ error }}
      </p>
      <div class="flex relative text-sm h-10 mt-2">
        <input
          ref="inputRef"
          :placeholder="$t('text-distance-name-or-zip')"
          :aria-label="$t('text-distance-name-or-zip')"
          :class="[ 'w-full h-full flex item-center appearance-none transition duration-300 ease-in-out text-primary-dark text-xs font-bold overflow-hidden focus:outline-none focus:ring-0 border border-primary-dark rounded-full focus:border-primary-dark-hover px-5', modelValue ? 'placeholder:text-bolder' : 'placeholder:text-primary-light' ]"
          @change="handleChange"
          @keyup.enter="handleChange"
        >
        <button
          v-if="!modelValue"
          type="button"
          class="cursor-pointer h-full w-10 flex items-center justify-center absolute text-primary-dark transition-colors duration-200 focus:outline-none hover:text-accent-hover focus:text-accent-hover end-0"
          :aria-label="$t('text-allow-geolocation-here')"
          :title="$t('text-allow-geolocation-here')"
          @click="handleGetCoordinates"
        >
          <MapPinIcon class="w-4 h-4" />
        </button>
        <button
          v-else
          type="button"
          class="cursor-pointer h-full w-10 flex items-center justify-center absolute text-primary-dark transition-colors duration-200 focus:outline-none hover:text-accent-hover focus:text-accent-hover end-0"
          :aria-label="$t('text-close')"
          @click="handleClear"
        >
          <span class="sr-only">{{ $t('text-close') }}</span>
          <CloseIcon class="w-3.5 h-3.5 md:w-3 md:h-3" />
        </button>
        <slot />
      </div>
      <Combobox
        v-model="distance"
        :options="distances"
        name="distance"
        variant="outline"
        dimension="tiny"
        :allow-custom-value="true"
        :input-class-name="(modelValue && (distance === '0' || distance === 0) ? 'placeholder:text-primary-light' : 'placeholder:text-bolder') + ' text-xs font-bold border border-primary-dark rounded-full'"
        :placeholder="$t('text-distance-enter')"
        add-option-label="text-start-searching-km"
        :aria-label="$t('text-distance-select')"
        :display-value="distanceDisplayValue"
        :on-add-option="handleAddOption"
      />
      <Selectbox
        v-if="showCountry"
        v-model="countryId"
        :options="countries"
        name="country"
        variant="outline"
        dimension="tiny"
        class-name="w-full flex item-center appearance-none transition duration-300 ease-in-out text-primary-dark text-xs placeholder:text-primary-dark font-bold overflow-hidden focus:outline-none focus:ring-0 border border-primary-dark rounded-full focus:border-primary-dark-hover mt-2"
        :aria-label="$t('text-country')"
      />
    </div>
    <div ref="mapRef" class="hidden" />
  </div>
</template>

<script setup>
import _ from 'lodash-es';
import { ROUTES } from "@utils/routes";
import { useUIStore } from '@stores/ui';
import { useSelectedStore } from '@stores/selected';
import { useCustomerStore } from '@stores/customer';
import { useEnumStore } from '@stores/enum';
import MapPinIcon from "@components/icons/map-pin-icon";
import CloseIcon from "@components/icons/close-icon";
import PlusIcon from "@components/icons/plus-icon";
import MinusIcon from "@components/icons/minus-icon";
import Selectbox from "@components/ui/selectbox";
import Combobox from "@components/ui/combobox";

defineProps({
  showCountry: {
    type: Boolean,
    default: true
  },
  isDetail: {
    type: Boolean,
    default: false
  }
});

const config = useRuntimeConfig();
const { t } = useI18n();
const { $eventBus, $toast } = useNuxtApp();
const ui = useUIStore();
const store = useSelectedStore();
const customer = useCustomerStore();
const enums = useEnumStore();
const geolocation = useGeolocation();
const route = useRoute();
const routeLang = useRouteLang();
const name = computed(() => store.hasCurrentLocation() ? 'maxKmFromLocation' : 'maxKmFromHome');
const modelValue = ref(_.get(route.query, 'location', ''));
const countryId = ref(null);
const distance = ref(+_.get(route.query, 'maxKmFromHome', _.get(route.query, 'maxKmFromLocation', 0)));
const error = ref(t('text-distance-name-or-zip-note'));

const inputRef = ref(null);
const mapRef = ref(null);
const map = ref(null);
const service = ref(null);
const autocomplete = ref(null);

const isOpen = ref(true);
const distances = ref([
  { id: 0, name: t('text-custom-distance') },
  { id: 5, name: t('text-distance-near-km', { number: 5 }) },
  { id: 10, name: t('text-distance-near-km', { number: 10 }) },
  { id: 20, name: t('text-distance-near-km', { number: 20 }) },
  { id: 30, name: t('text-distance-near-km', { number: 30 }) },
  { id: 50, name: t('text-distance-near-km', { number: 50 }) },
  { id: 70, name: t('text-distance-near-km', { number: 70 }) },
  { id: 100, name: t('text-distance-near-km', { number: 100 }) },
  { id: 120, name: t('text-distance-near-km', { number: 120 }) },
  { id: 150, name: t('text-distance-near-km', { number: 150 }) },
  { id: 300, name: t('text-distance-near-km', { number: 300 }) },
  { id: 700, name: t('text-distance-near-km', { number: 700 }) },
]);

const handleAddOption = (value) => {
  const intValue = +`${value}`.replace(/\D/g, '');
  if (!_.find(distances.value, { id: intValue })) {
    distances.value.push({ id: intValue, name: t('text-distance-near-km', { number: intValue }) });
    distances.value = _.sortBy(distances.value, ['id']);
    nextTick(() => {
      distance.value = intValue;
    });
  }
}

handleAddOption(distance.value);

const countries = computed(() => _.concat([{ id: null, name: '', code: '' }], _.filter(enums?.countries || [], item => _.includes(['CZ', 'SK', 'PL', 'DE'], item.code))));

const setupCountry = (values) => {
  if (countryId.value === null && routeLang.code.value) {
    const code = { cs: 'cz', en: 'gb' }[routeLang.code.value] || routeLang.code.value;
    countryId.value = _.chain(values)
      .filter({ code: _.toUpper(code) })
      .first()
      .get('id')
      .value();
  }
}

watch(() => enums.countries, (values) => {
  setupCountry(values);
});

watch(() => route.query, (query) => {
  distance.value = +_.get(query, 'maxKmFromHome', _.get(query, 'maxKmFromLocation', 0));
  modelValue.value = _.get(query, 'location', '');
});

watch(() => distance.value, async (value) => {
  if (value === 0 || value === '0') {
    distance.value = 0;
    await updateQueryParams();
    error.value = t('text-distance-name-or-zip-note');
  } else {
    if (ui.displaySidebar) {
      closeSidebar();
    }
    await updateQueryParams();
  }
});

watch(() => countryId.value, (value) => {
  if (autocomplete.value && enums?.countries && enums?.countries?.length) {
    const code = _.chain(enums.countries).filter({ id: parseInt(value) }).first().get('code').toLower().value();
    autocomplete.value.setComponentRestrictions({country: code ? code : [] });
  }
});

watch(() => modelValue.value, (value) => {
  inputRef.value.value = value;
  onSubmit(+distance.value, value);
});

const distanceDisplayValue = (option) => (option.name === t('text-custom-distance') ? '' : option.name);

const toggleCollapse = () => {
  isOpen.value = !isOpen.value;
}

const closeSidebar = () => {
  $eventBus.emit('sidebar:close');
};

const routeName = computed(() => route?.name || '');

const updateQuery = async (maxKmFromHome, maxKmFromLocation, location) => {
  const params = { ...route.query };
  if (maxKmFromHome) {
    params.maxKmFromHome = maxKmFromHome;
  } else {
    delete params['maxKmFromHome'];
  }
  if (maxKmFromLocation) {
    params.maxKmFromLocation = maxKmFromLocation;
  } else {
    delete params['maxKmFromLocation'];
  }
  if (location) {
    params.location = location;
  } else {
    delete params['location'];
  }
  if (geolocation.coordinates.value.latitude && (maxKmFromHome || maxKmFromLocation || location)) {
    params.latitude = geolocation.coordinates.value.latitude;
  } else {
    delete params['latitude'];
  }
  if (geolocation.coordinates.value.longitude && (maxKmFromHome || maxKmFromLocation || location)) {
    params.longitude = geolocation.coordinates.value.longitude;
  } else {
    delete params['longitude'];
  }
  if (routeName.value.startsWith('classified-ads___') ||
    routeName.value.startsWith('category-slug___') ||
    routeName.value.startsWith('offers___') ||
    routeName.value.startsWith('offers-slug___') ||
    routeName.value.startsWith('requests___') ||
    routeName.value.startsWith('requests-slug___') ||
    routeName.value.startsWith('auctions___') ||
    routeName.value.startsWith('auctions-slug___')
  ) {
    await navigatorTo({ path: routeName.value.startsWith('classified-ads___') ||
      routeName.value.startsWith('category-slug___') ||
      routeName.value.startsWith('offers___') ||
      routeName.value.startsWith('offers-slug___') ||
      routeName.value.startsWith('requests___') ||
      routeName.value.startsWith('requests-slug___') ||
      routeName.value.startsWith('auctions___') ||
      routeName.value.startsWith('auctions-slug___') ? route.path : translatePath(ROUTES.CODE.ADS), query: params });
  }
}

const onSubmit = async (value, location) => {
  store.setLoadResetOthers();
  if (value && !customer.hasHomeLocation() && !store.hasCurrentLocation()) {
    $toast.error(t('error.filter.maxKm.check'));
    await updateQuery(null, null, '');
  } else if (name.value === 'maxKmFromHome') {
    if (value && !customer.hasHomeLocation()) {
      $toast.error(t('error.filter.maxKmFromHome.check'));
    } else {
      await updateQuery(value, null, location);
    }
  } else if (name.value === 'maxKmFromLocation') {
    if (value && !store.hasCurrentLocation()) {
      $toast.error(t('error.filter.maxKmFromLocation.check'));
    } else {
      await updateQuery(null, value, location);
    }
  } else {
    await updateQuery(null, null, location);
  }
}

const handleGetCoordinates = async () => {
  geolocation.withLocation(async () => {
    await geolocation.getPlace();
    error.value = ' ';
    if (store.hasCurrentLocation()) {
      $toast.success(t('success.geolocation.accepted'));
    } else {
      $toast.error(t('error.geolocation.denied'));
    }
  });
};

const updateQueryParams = async () => await onSubmit(+distance.value, modelValue.value || inputRef.value?.value);

const handleChange = () => _.delay(() => {
  if (modelValue.value !== inputRef.value?.value || (modelValue.value === '' && inputRef.value?.value === '')) {
    error.value = t('text-distance-name-or-zip-error');
    inputRef.value.value = '';
  }
}, 200);

const handleClear = async () => {
  modelValue.value = null;
  inputRef.value.value = null;
  geolocation.coordinates.value.latitude = null;
  geolocation.coordinates.value.longitude = null;
  store.setCoordinates(geolocation.coordinates.value);
  distance.value = 0;
  if (ui.displaySidebar) {
    closeSidebar();
  }
  await updateQueryParams();
  error.value = t('text-distance-name-or-zip-note');
}

const updateByGeolocationPlace = () => {
  modelValue.value = geolocation.place.value?.formatted_address || '';
  if (geolocation.place.value?.geometry?.location) {
    geolocation.coordinates.value.latitude = geolocation.place.value.geometry.location.latitude = geolocation.place.value.geometry.location.lat();
    geolocation.coordinates.value.longitude = geolocation.place.value.geometry.location.longitude = geolocation.place.value.geometry.location.lng();
    if (!distance.value) {
      distance.value = 10;
    }
  }
}

const initAutocomplete = () => {
  if (!window.google) return;

  map.value = map.value || new window.google.maps.Map(mapRef.value, { mapId: config.public.googleMapId });
  service.value = service.value || new window.google.maps.places.PlacesService(map.value);

  if (autocomplete.value) {
    autocomplete.value.unbindAll();
    autocomplete.value = null;
  }

  const input = inputRef.value;
  const options = {
    types: ['locality', 'postal_code'],
    language: routeLang.code.value
  };

  autocomplete.value = new window.google.maps.places.Autocomplete(input, options);

  autocomplete.value.addListener('place_changed', () => {
    error.value = ' ';
    geolocation.place.value = autocomplete.value.getPlace() || {};

    if (geolocation.place.value.place_id) {
      const request = {
        placeId: geolocation.place.value.place_id,
        language: routeLang.code.value
      };

      service.value.getDetails(request, (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          geolocation.place.value = place;
        }
        updateByGeolocationPlace();
      });
    }
    updateByGeolocationPlace();
  });
}

const placeUpdate = () => {
  modelValue.value = geolocation.place.value?.formatted_address || geolocation.place.value?.formattedAddress || '';
};

onMounted(async () => {
  if (enums?.countries && enums?.countries?.length) {
    setupCountry(enums.countries);
  }
  if (import.meta.client && window && window.google) {
    initAutocomplete();
  }
  if (import.meta.client && modelValue.value) {
    inputRef.value.value = modelValue.value;
  }
  $eventBus.on('place:update', placeUpdate);
});

onUnmounted(() => {
  $eventBus.off('place:update', placeUpdate);
});

</script>
