import * as Sentry from "@sentry/vue";
import { API_ENDPOINTS } from "@utils/api/endpoints";
import { CART_TYPE } from "@utils/constants";
import { useAuthStore } from '@stores/auth';
import { useCartStore } from '@stores/cart';

export default async function () {
  const config = useRuntimeConfig();
  const auth = useAuthStore();
  const cart = useCartStore();

  cart.loading = true;
  cart.list.items = [];

  if (!auth.isLoggedIn || !auth.idSupplier) {
    cart.loading = false;
    return;
  }

  try {
    const data = await $fetch(`${config.public.appBaseUrl}${API_ENDPOINTS.ESHOP_CHECKOUT_CART}`, {
      method: 'GET',
      params: {
        type: CART_TYPE.BUYER,
        page: cart.pagination.page,
        limit: cart.pagination.itemsPerPage,
        status: 'active'
      }
    });

    cart.list.items = data?.items || [];
    cart.pagination = data?.pagination || cart.pagination;
  } catch (error) {
    Sentry.captureException(error);
  }

  cart.loading = false;
}
